import React from "react"
import Layout from "../components/layout"
import SEO from "../components/seo"

const About = () => (
  <Layout>
    <SEO
      title="Kullanıcı Antlaşması"
      description="flatart.com.tr gizlilik sözleşmesi"
    />
    <div className="content-page">
      <div
        className="content container"
      >
        <h1>Gizlilik Sözleşmesi</h1>

<h2>Flatart Agency Gizlilik Sözleşmesi</h2>

<p>Bu gizlilik sözleşmesi flatart.com.tr adresine ait kişisel Tanımlayıcı Bilgilerinin , çevrimiçi olarak nasıl kullanıldığıyla ilgili kaygılı kişiler için oluşturulmuştur. Bilgi güvenliği koşullarında da tanımlandığı gibi kendi başına veya farklı bilgilerle birlikte tek bir bireyin veya çevredeki bir bireyin tanımlanması, ulaşılması veya konumlandırılması için kullanılan bilgidir. Bilgilerinizi nasıl topladığımızı, kullandığımızı, koruduğumuzu veya diğer bir deyişle Kişisel Tanımlayıcı Bilgileriniz ile ne yaptığımızı kesin bir şekilde anlamak için dikkatlice bu gizlilik sözleşmesini okuyunuz.
</p>
<h3>Flatart Agency sitemizi yada mobil uygulamamızı ziyaret edenlerden hangi kişisel bilgileri topluyoruz?</h3>
<p>Sitemize kayıt olurken yada flatart.com.tr adresimiz üzerinden mail bültene kayıt olduğunuzda tecrübenizi daha iyi bir hale getirebilmek için uygun görüldüğü şekilde e- mail adresiniz veya farklı bilgileriniz sorulabilir.</p>

<h3>Flatart Agency bilgileri kullanıcıdan ne zaman toplar?</h3>

<p>Bir bültene abone olduğunuz zaman veya sitemize bir bilgi girdiğinizde bilgilerinizi topluyoruz.</p>

<h3>flatart.com.tr adresinde bilgilerinizi nasıl kullanıyoruz?</h3>

<p>flatart.com.tr adresine kayıt olduğunuzda, satın alma gerçekleştirdiğinizde, bültenimiz için kayıt olduğunuzda, bir anketi veya pazarlama iletişimini yanıtladığınızda, sitede dolaşırken veya sitedeki bazı diğer özellikleri kullandığınızda sizden topladığımız verileri altta belirtildiği gibi kullanabiliriz: o Size siparişiniz hakkında veya diğer ürünler ve servisler hakkında periyodik e-mailler atmak için.
</p>
<h3>flatart.com.tr adresini nasıl koruyoruz?
</h3>
<p>Sistem açığı taramasını kullanmıyoruz ve/veya PCI standartlarında tarama Biz sadece makale ve bilgi sağlıyoruz. flatart.com.tr adresi üzerinde kesinlikle kredi kartı numaralarını istemiyoruz. Düzenli olarak kötücül yazılım taramasını kullanıyoruz. Kişisel bilgileriniz,
güvenliği sağlanmış	ağlarla korunmaktadır ve bu tür ağlara özel erişim yetkileri olan ve bu bilgileri gizli tutmakla yükümlü sınırlı sayıdaki kişi tarafından erişilebilir. Ayrıca sağladığınız tüm hassas/kredi bilgileri Secure Socket Layer (SSL) teknolojisi ile şifrelenmektedir. Bir kullanıcı, bilgisini girdiğinde, gönderdiğinde veya bilgisine erişim sağladığında kullanıcının kişisel bilgilerini korumak için çeşitli güvenlik tedbirlerini uyguluyoruz. Tüm işlemler ağ	geçidi sağlayıcısı kanalıyla işleme tabi tutulur ve bizim sunucularımızda depolanmaz veya işleme tabi tutulmaz.</p>

<h3>Flatart Agency Çerezleri kullanıyor mu?</h3>

<p>Evet çerezleri kullanıyoruz. Çerezler, bir web sitesinin veya bu web sitesinin servis sağlayıcısı tarafından internet tarayıcınız (eğer izin verdiyseniz) ile sabit diskinize aktarılan küçük dosyalardır. Çerezler
flatart.com.tr adresini ziyaretinizde veya servis sağlayıcısının web tarayıcınızı hatırlamasına ve bazı bilgileri yakalayıp hatırlamasını sağlar.
</p>
<p><b>Çerezleri altta belirtilen durumları çin kullanıyoruz:</b></p>

<p>Gelecekte daha iyi bir site deneyimi ve iyileştirilmiş	araçlar için site trafiği ve site etkileşimlerinden topladığımız verileri derliyoruz. Ayrıca, güvenilir üçüncü parti servisleri kendi iyiliğimiz için kullanabiliriz. Bilgisayarınızın, çerezlerin
yollandıkları her zaman sizi uyarmasını veya çerezleri tamamen kapatmayı siz seçebilirsiniz. Bunu internet tarayıcı ayarlarınıza girerek yapabilirsiniz. Her tarayıcı biraz farklı olduğu için bu ayarları değiştirmenin en doğru yolunu öğrenmek için tarayıcınızın Yardım Menüsünü kullanabilirsiniz. Eğer çerezleri tamamen kapatırsanız Flatart Agency websitemizde bazı özellikler devre dışı kalacaktır. Bu özelliği devre dışı bırakmanız sizin Flatart Agency ziyaret deneyiminizi kesinlikle etkilemez.</p>

<h2>Flatart Agency Üçüncü Parti Uygulamaların Kullanımı</h2>

<p>Kişiyi tanımlamak için kullanılan bilgilerinizi kesinlikle satmıyoruz, ticaretini yapmıyoruz veya üçüncü şahıslara yollamıyoruz. Web sitesi barındırma hizmeti partnerleri ve sitemizin işlemesinde, işimizin iletiminde veya kullanıcılarımıza sunulmasında bize yardımcı olan diğer taraflar da bu bilgilerin gizli tutulması konusunda hemfikir olduğu takdirde yukarıdaki şartlar onları kapsamaz. Verilmesi yasaya uygun olduğunda, site politikamızı uygulamak için veya bizim ve başkalarının haklarını, mallarını veya güvenliklerini sağlamak için bilgiler verilebilir. Bununla birlikte kişisel olmayan tanımlayıcı ziyaretçi bilgileri, Flatart Agency pazarlama, reklam ve diğer kullanımlar için diğer şahıslara sağlanabilir.</p>


<h3>Flatart Agency Üçüncü Parti bağlantılar</h3>
<p>Bazı zamanlarda, bizim yetkimizle üçüncü parti ürünleri veya servisleri web sitemize katabilir ve sunabiliriz. Bu üçüncü parti siteler ayrı ve bağımsız gizlilik sözleşmelerine sahiptir. Bu sebeple, bu bağlı sitelerdeki içerik ve aktivitelerle ilgili herhangi bir yükümlülük ve sorumluluğumuz bulunmamaktadır. Yine de sitemizin bütünlüğünü korumaya çalışıyoruz ve bu sitelerle ilgili geri dönütleri almaya sıcak bakıyoruz.</p>

<p>Google’ın reklam gereklilikleri, Google'ın Reklam İlkeleri ile özetlenebilir. Bu ilkeler olumlu bir deneyim sağlamak için alttaki linkte bir araya toplanmıştır.
</p>
<p><a href="https://support.google.com/adwordspolicy/answer/1316548?hl=tr">https://support.google.com/adwordspolicy/answer/1316548?hl=tr</a> Flatart Agency Google AdSense dönem dönem kullanmaktadır. Üçüncü parti bir sağlayıcı olarak Google, sitemizde reklamları sunmak için çerezleri kullanır. Google, DART çerezleri kullanarak kullanıcılarımızın sitemize yaptığı önceki ziyaretlere ve internetteki diğer sitelere yaptıkları ziyaretlere göre onlara reklam sunmasını sağlar. Kullanıcılar Google Reklam ve İçerik Ağı gizlilik sözleşmesini ziyaret ederek DART çerezlerinin kullanımı dışında kalabilirler. Altta belirtilenleri uyguluyoruz:
Google AdSense ile Tekrar Pazarlama Google Görüntülü Reklam Ağı Raporlaması
DoubleClick Platform Entegrasyonu Demografi ve
İlgi Alanları Raporlaması Google gibi üçüncü parti sağlayıcılarla birlikte birinci parti
çerezleri (Google Analytics çerezleri) ve üçüncü parti çerezleri (DoubleClick çerezleri) veya diğer üçüncü parti tanımlayıcılarını, kullanıcının reklam etkileşimlerini ve sitemizle ilgili diğer reklam servisi fonksiyonlarına ilişkin verileri işlemek için kullanıyoruz.</p>

<h3>Reklamları Devre Dışı Bırakma</h3>

<p>Kullanıcılar Google Reklam Ayarları sayfasındaki ayarları kullanarak Google’ın reklamları nasıl sunacağıyla ilgili değişiklikler yapabilirler. Alternatif olarak Network Advertising Initiative Opt Out sayfasını ziyaret ederek veya Google Analytics Devre Dışı tarayıcı eklentisini kullanarak devre dışı bırakabilirler. Gizlilik sözleşmemizdeki herhangi bir değişiklik halinde e-mail yoluyla bilgilendirileceksiniz. Bize e- mail atarak kişisel bilgilerinizi değiştirebilirsiniz.</p>


<h3>COPPA (Çocukların Çevrimiçi Gizliliğini Koruma Yasası)</h3>
<p>COPPA, 13 yaş	altı çocuklardan kişisel bilgilerin toplanması noktasında ebeveynlerini sorumlu tutar. Federal Ticaret Komisyonu ve Birleşik Devletler tüketici koruma ajansı web site ve online servis operatörlerinin çocukların çevrimiçi gizliliğini ve çevrimiçi güvenliğini korumak için neler yapması gerektiğini açıkça belirten COPPA kanununu yürütmektedir. On üç yaş	altı çocuklara spesifik olarak pazarlama yapmıyoruz.</p>
<h3>Adil Bilgi Uygulamaları</h3>

<p>Adil Bilgi Uygulamaları Prensipleri sahip olduğu kavramlarla tüm dünyadaki veri koruma yasalarının gelişiminde önemli bir rol oynamıştır. Adil Bilgi Uygulamaları Prensiplerini ve nasıl uygulanabileceklerini anlamak kişisel bilgiyi koruyan çeşitli yasalara uymak için kritik bir önem arz etmektedir. Bir veri ihlali olması durumunda Adil Bilgi Uygulamalarına uymak için sizi 7 iş günü içerisinde e-mail yolu ile bilgilendireceğiz. Bireylerin yasalara uymada başarısız olmuş	veri toplayıcılar ve işleyenlere karşı yasal takip ve yürütme hakkını gerektiren Bireysel Redress İlkesiyle de mutabığız. Bu ilke, bireylerin sadece veri kullananlara karşı yasal hakları olmasını değil aynı zamanda bireylerin mahkemeye veya hükümet ajanslarına yasaya uymayan veri işlemcilerini kovuşturmak için başvurmasını da gerektirir.</p>

<h2>CAN SPAM Kanunu</h2>
<p>
CAN-SPAM Kanunu ticari e-mail kurallarını koyan, ticari mesaj gerekliliklerini belirleyen, alıcılara e-maillerin atılmasının durdurulma hakkını veren ve bu kurallara karşı gerçekleşen herhangi bir ihlal için cezai yaptırımlar uygulayan bir yasadır. Size bilgileri yollamak, araştırmalara cevap vermek ve/veya diğer istek veya sorular için e-mail adresinizi alıyoruz.
CANSPAM’e uymak için aşağıda belirtilenleri uyguluyoruz:</p>
<ul>
	<li>Yanlış	veya yanıltıcı konuların veya e-mail adreslerini kullanmıyoruz.</li>
	<li>Mesajı anlaşılabilir bir şekilde reklam olarak tanımlıyoruz. o İşletmemizin veya site genel merkezimizin fiziksel adresine yer veriyoruz.</li>
	<li>Eğer herhangi biri kullanılıyorsa üçüncü parti e-mail pazarlama servislerinin uyumluluğunu izliyoruz. o Devre dışı bırakma/ abonelikten çık isteklerini hızlı bir şekilde değerlendiriyoruz.
</li>
	<li>Her mailin sonuna yerleştirdiğimiz bağlantı ile abonelikten çıkmak için seçenek sağlıyoruz.</li>
	<li>Eğer ilerideki mailler için abonelikten çıkmak istiyorsanız bize mail atabilirsiniz.</li>
	<li> Maillerin sonundaki talimatları takip edin. Sizi tüm iletişim ağımızdan çıkarıyoruz.</li>

</ul>


<p><b>Bize Ulaşın</b></p>

<p>Eğer bu gizlilik sözleşmesiyle ilgili herhangi bir sorunuz varsa alttaki iletişim bilgilerinden bize ulaşabilirsiniz.</p>

<p>Flatart Agency</p>
<p><a href="/kullanici-antlasmasi/">flatart.com.tr</a></p>


<p><a href="tel:+908509880200">+90 (850) 988 02 00</a></p>
      </div>
    </div>
  </Layout>
)

export default About
